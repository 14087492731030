import { FileSizes } from '@customer-portal/constants';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styled from '../../assets/css/CustomerPortalEditProfile';
import UpdateIcon from '../../assets/img/Upload_Photo_Icon.png';
// Clients
import { axiosPatch } from '../../client/axios';
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import Container from '../../components/CustomerPortal-New-Container';
// Components
import Label from '../../components/Label';
import EditProfileActivity from '../../components/user_profile/UserActivity';
import EditProfileCompaniesAndPermissions from '../../components/user_profile/UserCompaniesAndPermissions';
import EditDetailsForm from '../../components/user_profile/UserDetails';
// Constants
import {
  UPLOAD_AVATAR_URL,
  VIEW_AVATAR_URL,
} from '../../constants/network.constants';
import { EDIT_PROFILE } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Utils
import { UserPermissionsHelper } from '../../lib/UserPermissions';
// Contexts
import { StoreContext } from '../../store';
import NoUserIcon from './../../assets/img/No_User_Profile.svg';
// Images
import AddIcon from './../../assets/img/svg/action_icons/Add.svg';
import CompanyIcon from './../../assets/img/svg/edit_profile/Company_Icon.svg';
import CountryIcon from './../../assets/img/svg/edit_profile/Country_Icon.svg';
import PhoneIcon from './../../assets/img/svg/edit_profile/Phone_Icon.svg';

const CustomerPortalPageEditProfile = (props: any) => {
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Local state
  const [ isEditing, setEditing ] = useState(false);
  const [ editProfilePhoto, setEditProfilePhoto ] = useState(false);
  // Constants
  const isUiPath = UserPermissionsHelper.isUiPathUser();
  const isPartOfMultipleCompanies = UserPermissionsHelper.isPartOfMultipleCompanies();
  const fileInput = React.createRef<any>();
  // Translate method
  const { t } = useTranslation('common');

  // Methods
  const handleEditClick = () => {
    setEditing(!isEditing);
  };

  const joinedDate = () => {
    if (!state.createdOn) {
      return '';
    }

    const dateObj = new Date(state.createdOn);
    return `${dateObj.getUTCMonth() +
      1}/${dateObj.getUTCDate()}/${dateObj.getUTCFullYear()}`;
  };

  const profilePhoto = () => {
    if (!state.profilePhoto) {
      return (
        <img
          src={NoUserIcon}
          alt="No User Placeholder"
          data-testid="ProfilePage__noProfileImg"
        />
      );
    }

    return (
      <img
        src={`${VIEW_AVATAR_URL}/${state.profilePhoto}`}
        alt="User Profile"
      />
    );
  };

  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileUploadInputChange = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file && file.size > FileSizes.TwentyFiveMegabytes) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `Error uploading document: File exceeds 25MB!`,
      });
      return;
    }

    // Package into form Data
    const formData = new FormData();
    formData.set('photo', file);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    try {
      dispatch({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `Please wait while your file is being uploaded to the server. This may take a few minutes...`,
      });

      const result = await axiosPatch(
        `${UPLOAD_AVATAR_URL}/${state.userId}`,
        state.companyId,
        await getAccessToken(),
        formData,
        config
      );

      if (
        result.data?.statusCode &&
        result.data.data?.picture &&
        result.data.statusCode === 201
      ) {
        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: `Profile photo has been successfully updated!`,
        });
        dispatch({
          type: 'setProfilePhoto',
          payload: result.data.data.picture,
        });
        setEditing(false);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.data
        ? `Error uploading profile photo: ${err.response.data.data}`
        : `Error uploading profile photo`;
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      console.log('Error uploading profile photo');
    }
  };

  useTrackPageViewEvent(EDIT_PROFILE);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Edit Profile');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('profile_page_title', 'Profile | Customer Portal')}</title>
      </Helmet>
      <styled.PageWrapper data-testid="ProfilePage__pageWrapper">
        <styled.Hero />
        <Container cssClass="EditProfile__Container">
          <div
            className="EditProfile__Avatar"
            data-testid="ProfilePage__avatarContainer"
            onClick={editProfilePhoto ? handleUploadButtonClick : undefined}
            onMouseEnter={() => setEditProfilePhoto(true)}
            onMouseLeave={() => setEditProfilePhoto(false)}
          >
            <styled.AvatarEditScreen
              className={editProfilePhoto ? 'Active' : ''}
              data-testid="ProfilePage__avatarEditState"
            >
              <img
                src={UpdateIcon}
                alt="Update Icon"
                className="EditProfile__Update-Photo-Icon"
              />
            </styled.AvatarEditScreen>
            <styled.avatarImg>{profilePhoto()}</styled.avatarImg>
            <form className="CustomerPortalUploadForm">
              <input
                className="CustomerPortalUploadForm__Input"
                data-testid="ProfilePage__uploadForm"
                name="fileInput"
                type="file"
                onChange={handleFileUploadInputChange}
                ref={fileInput}
              />
            </form>
          </div>

          <div className="EditProfile__Details">
            <div className="EditProfile__Details-Contact-Info">
              <div className="EditProfile__Contact-Info-Text">
                <styled.profileDetails>
                  {t('user_profile_details_headline', 'Profile Details')}
                </styled.profileDetails>
                <styled.profileName data-testid="ProfilePage__userName">
                  {state.userName}
                </styled.profileName>
                <div className="EditProfile__Email-Status">
                  <styled.profileEmail data-testid="ProfilePage__userEmail">
                    {state.userEmail}
                  </styled.profileEmail>
                  <Label
                    text="Active"
                    type="Active"
                    data-testid="ProfilePage__userStatus"
                  />
                </div>
              </div>
              {!isEditing && !state.isUiPath && (
                <div
                  className="EditProfile__Edit-Link"
                  onClick={handleEditClick}
                  data-testid="ProfilePage__editProfile"
                >
                  <p className="EditProfile__Edit-Link-Text Bold">
                    {t('user_profile_edit_profile_btn', 'Edit Profile')}
                  </p>
                  <img
                    src={AddIcon}
                    className="EditProfile__Edit-Link-Icon"
                    alt="Add Icon"
                  />
                </div>
              )}
            </div>

            {!state.isUiPath && (
              <>
                <div className="EditProfile__Details-Rule" />
                <styled.EditProfileStats>
                  {isEditing ? (
                    <div
                      className="EditProfile__Details-Form"
                      data-testid="ProfilePage__formContainer"
                    >
                      <EditDetailsForm closeEditScreen={handleEditClick} />
                    </div>
                  ) : (
                    <div className="EditProfile__Main-Stats-Container">
                      <div className="EditProfile__Stat">
                        <p className="EditProfile__Stat-Title Bold">
                          {t('user_profile_phone_no_label', 'Phone No')}
                        </p>
                        <div className="EditProfile__Stat-Body">
                          <img
                            src={PhoneIcon}
                            className="EditProfile__Stat-Icon"
                            alt="Phone Icon"
                          />
                          <p
                            className="EditProfile__Stat-Body-Text"
                            data-testid="ProfilePage__userPhone"
                          >
                            {state.phoneNumber}
                          </p>
                        </div>
                      </div>

                      <div className="EditProfile__Stat">
                        <p className="EditProfile__Stat-Title Bold">
                          {t(
                            'user_profile_job_title_label',
                            'Company Title'
                          )}
                        </p>
                        <div className="EditProfile__Stat-Body">
                          <img
                            src={CompanyIcon}
                            className="EditProfile__Stat-Icon"
                            alt="Company Icon"
                          />
                          <p
                            className="EditProfile__Stat-Body-Text"
                            data-testid="ProfilePage__userJobTitle"
                          >
                            {state.jobTitle}
                          </p>
                        </div>
                      </div>

                      <div className="EditProfile__Stat">
                        <p className="EditProfile__Stat-Title Bold">
                          {t('user_profile_country_label', 'Country')}
                        </p>
                        <div className="EditProfile__Stat-Body">
                          <img
                            src={CountryIcon}
                            className="EditProfile__Stat-Icon"
                            alt="Country Icon"
                          />
                          <p
                            className="EditProfile__Stat-Body-Text"
                            data-testid="ProfilePage__userCountry"
                          >
                            {state.country}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="EditProfile__Secondary-Stats-Container">
                    <div className="EditProfile__Stat-Small">
                      <p className="EditProfile__Stat-Title-Thin">
                        {t('user_profile_joined_on_label', 'Joined on')}
                      </p>
                      <p
                        className="EditProfile__Stat-Title Bold"
                        data-testid="ProfilePage__joinedDate"
                      >
                        {joinedDate()}
                      </p>
                    </div>
                    {!isPartOfMultipleCompanies && (
                      <div className="EditProfile__Stat-Small">
                        <p className="EditProfile__Stat-Title-Thin">
                          {t('user_profile_part_of_label', 'Part of')}
                        </p>
                        <p
                          className="EditProfile__Stat-Title Bold"
                          data-testid="ProfilePage__partOf"
                        >
                          {state.companyName}
                        </p>
                      </div>
                    )}
                  </div>
                </styled.EditProfileStats>
              </>
            )}
          </div>
        </Container>
        {/* External user */}
        {!isUiPath && (
          <EditProfileCompaniesAndPermissions />
        )}
        {/* User activity */}
        <EditProfileActivity data-testid="ProfilePage__userActivity" />
      </styled.PageWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageEditProfile;
