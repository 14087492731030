import { CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
// Components
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Support/Case';
import {
  axiosPost,
  axiosPublicPost,
} from '../../../client/axios';
import {
  PUBLIC_VALIDATE_USER_LICENSE_CODE_URL,
  VALIDATE_USER_LICENSE_CODE_URL,
} from '../../../constants/network.constants';
// Constants
import type { DeploymentTypeOption } from '../../../constants/support.constants';
import {
  INVALID_ENTITLEMENT_CASE_CREATION_ERROR,
  LICENSE_HELP_TEXT,
  LICENSE_PLACEHOLDER,
  LICENSE_VALIDATION_API_ERROR,
  LICENSE_VALIDATION_WARNING,
  LicenseAndDeploymentTypeMatch,
  LicenseDeploymentType,
  MAX_DESCRIPTION_LENGTH,
  SAP_SUPPORT_URL,
  VALID_LICENSE,
  VersionDropdownField,
} from '../../../constants/support.constants';
import {
  CREATE_CASE_PAGE,
  INVALID_LICENSE_ENTITLEMENTS,
} from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
import type { DropDownItemData } from '../../../interfaces/sfdc.interface';
// Interface
import type {
  CasePageProps,
  NewIFormDataKeys,
} from '../../../interfaces/support.interface';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
// Lib
import { getLicenseOptions } from '../../../lib/support.util';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';
import SelectWithManualSearch from '../../Select-With-Manual-Search';
import SelectWithSearch from '../../Select-With-Search';
import TextLabel from '../../TextLabel';

const DescribeIssuePage = (props: CasePageProps) => {
  const { t } = useTranslation('common');
  const baseClass = 'SupportCaseBody';
  const {
    formData,
    setFormData,
    filteredSupportFormFieldMappings,
    isRequiredLicense,
    licensesData,
    supportIdValidation,
    setSupportIdValidation,
    supportIdValidationClassSufix,
    setLicenseInfo,
    primaryVersion,
    setPrimaryVersion,
    loadingSupportIdValidation,
    setLoadingSupportIdValidation,
    setIsCsrfTokenSet,
    setIsRateLimited,
  } = props;
  const isPublic = props.isPublic ?? false;
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // When isPublic is true, the user's state always has isRegistered as false, but we add the first check to be explicit
  const isRegistered = !isPublic && UserPermissionsHelper.isRegisteredUser();
  const [ licensesOptions, setLicensesOptions ] = useState<any[]>([]);
  const [ isOpenLicense, setIsOpenLicense ] = useState(false);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const validateLicenseCode = useCallback(
    debounce(async (value: string, deploymentTypeValue: string) => {
      try {
        let result;
        if (isPublic) {
          result = await axiosPublicPost(PUBLIC_VALIDATE_USER_LICENSE_CODE_URL, {
            id: value,
            checkWindowDays: true,
          });
        } else {
          const body = {
            id: value,
            companyId: state.companyId,
            checkWindowDays: true,
          };
          result = await axiosPost(
            VALIDATE_USER_LICENSE_CODE_URL,
            isRegistered ? state.companyId : '',
            await getAccessToken(),
            body
          );
        }

        const {
          accountId,
          licenseCode,
          subscriptionCode,
          subscriptionType,
          deploymentTypes,
          licenseEndDate,
          isCommunity,
          isTrial,
          hasValidEntitlements,
        } = result.data;
        if (result.status !== 200 || isCommunity) {
          throw new Error(`Invalid license used: ${JSON.stringify(result.data)}`);
        } else if (!hasValidEntitlements) {
          throw new Error('Invalid entitlements');
        }

        const licenseAndDeploymentTypeMatch: LicenseDeploymentType[] =
          LicenseAndDeploymentTypeMatch[deploymentTypeValue as DeploymentTypeOption];

        if (!deploymentTypes || deploymentTypes.length === 0 || deploymentTypes.includes(LicenseDeploymentType.NO_DEPLOYMENT_TYPE) ||
          deploymentTypes.some((type: any) => licenseAndDeploymentTypeMatch.includes(type))) {
          setSupportIdValidation(VALID_LICENSE);
        } else {
          setSupportIdValidation(LICENSE_VALIDATION_WARNING);
        }

        setLicenseInfo({
          accountId: accountId ?? '',
          licenseCode,
          subscriptionCode,
          subscriptionType,
          endDate: licenseEndDate,
          isTrial,
          isCommunity,
        });
      } catch (e) {
        if (e.message === 'Invalid entitlements') {
          setSupportIdValidation(INVALID_ENTITLEMENT_CASE_CREATION_ERROR);
          triggerTrackEventWithStateData(INVALID_LICENSE_ENTITLEMENTS, { page: CREATE_CASE_PAGE });
        } else {
          setSupportIdValidation(LICENSE_VALIDATION_API_ERROR);
        }
        setLicenseInfo({
          accountId: '',
          licenseCode: '',
          subscriptionCode: '',
          subscriptionType: '',
          endDate: '',
          isTrial: false,
          isCommunity: false,
        });

        if (e.response?.status === 401 && setIsCsrfTokenSet) {
          setIsCsrfTokenSet(false);
          return;
        }
        if (e.response?.status === 429 && setIsRateLimited) {
          setIsRateLimited(true);
          return;
        }
      } finally {
        setLoadingSupportIdValidation(false);
      }
    }, 1000),
    [ licensesOptions ]
  );

  const handleSelectWithManualOptionChange = async (value: string, inputType: | 'supportId') => {
    if (!value) {
      setSupportIdValidation(null);
      setLicenseInfo({
        accountId: '',
        licenseCode: '',
        subscriptionType: '',
        subscriptionCode: '',
        endDate: '',
        isTrial: false,
        isCommunity: false,
      });
      return;
    }
    const newFormData = { ...formData };
    newFormData[inputType] = value;
    delete newFormData.errors[inputType];
    if (inputType === 'supportId') {
      setFormData(newFormData);
      setLicenseInfo({
        accountId: '',
        licenseCode: '',
        subscriptionType: '',
        subscriptionCode: '',
        endDate: '',
        isTrial: false,
        isCommunity: false,
      });
      setLoadingSupportIdValidation(true);
      setSupportIdValidation(null);
      await validateLicenseCode(value, formData.deploymentType.value);
    }
  };

  const handleSelectOptionChange = async (
    option: DropDownItemData,
    inputType:
    | 'deploymentType'
    | 'product'
    | 'productComponentVersion'
    | 'studioOrRobotVersion'
    | 'orchestratorVersion'
    | 'automationSuiteVersion'
  ) => {
    const newFormData = { ...formData };
    newFormData[inputType] = option;
    delete newFormData.errors[inputType];
    switch (inputType) {
      // Reset versions and package names when product changes because each product has different versions
      case 'deploymentType': {
        newFormData.product = props.deploymentTypes[0];
        newFormData.orchestratorVersion = props.orchestratorVersions[0];
        newFormData.studioOrRobotVersion = props.studioVersions[0];
        newFormData.productComponentVersion = props.deploymentTypes[0];
        newFormData.siteUrl = '';
        delete newFormData.errors.supportId;
        setPrimaryVersion(VersionDropdownField.NONE);

        const deploymentTypeValue = option.label;
        setLicensesOptions(getLicenseOptions(
          LicenseAndDeploymentTypeMatch[deploymentTypeValue as DeploymentTypeOption],
          licensesData,
          state.companyLicenses,
          state.companyName)
        );

        if (formData.supportId && formData.supportId !== '') {
          setLoadingSupportIdValidation(true);
          setSupportIdValidation(null);
          await validateLicenseCode(formData.supportId, deploymentTypeValue);
        }

        break;
      }
      case 'product': {
        newFormData.orchestratorVersion = props.orchestratorVersions[0];
        newFormData.studioOrRobotVersion = props.studioVersions[0];
        newFormData.productComponentVersion = props.deploymentTypes[0];
        if (option.label.includes('Embedded Cloud Elements')) {
          newFormData.supportId = '';
          setSupportIdValidation(null);
        }
        break;
      }
      default: {
        break;
      }
    }
    setFormData(newFormData);
  };

  const handleInputChange = async (event: any, inputType: NewIFormDataKeys) => {
    const value: string = event.target.value;
    const newFormData = { ...formData };
    newFormData[inputType] = value;
    delete newFormData.errors[inputType];
    if (inputType === 'description') {
      if (value.length >= MAX_DESCRIPTION_LENGTH) {
        newFormData.errors.description = t(
          'support_form_error_message_invalid_desc_textbox_field',
          `Please condense the description field, as it exceeds {{maxDescriptionLength}} characters or more.`,
          { maxDescriptionLength: MAX_DESCRIPTION_LENGTH },
        );
      }
    }
    setFormData(newFormData);
  };

  const showPrimaryVersion = () => {
    switch (primaryVersion) {
      case VersionDropdownField.STUDIO_OR_ROBOT_VERSION:
        return (
          <FormControl
            className={`${baseClass}__Case-Field`}
            disabled={!filteredSupportFormFieldMappings?.studioOrRobotVersion}
          >
            <SelectWithSearch
              disabled={!filteredSupportFormFieldMappings?.studioOrRobotVersion}
              label={t(
                'support_form_studio_or_robot_version_label',
                'Studio/Robot Version'
              )}
              searchable
              className="Custom-Select--White studioOrRobotVersion-input"
              options={props.studioVersions}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'studioOrRobotVersion');
              }}
              value={formData.studioOrRobotVersion}
              data-testid="studioOrRobotVersion-input"
            />
            {formData.errors?.studioOrRobotVersion && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.studioOrRobotVersion}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>
        );
      case VersionDropdownField.ORCHESTRATOR_VERSION:
        return (
          <FormControl
            className={`${baseClass}__Case-Field`}
            disabled={!filteredSupportFormFieldMappings?.orchestratorVersion}
          >
            <SelectWithSearch
              disabled={!filteredSupportFormFieldMappings?.orchestratorVersion}
              label={t(
                'support_form_orchestrator_version_label',
                'Orchestrator Version'
              )}
              searchable
              className="Custom-Select--White orchestratorVersion-input"
              options={props.orchestratorVersions}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'orchestratorVersion');
              }}
              value={formData.orchestratorVersion}
              data-testid="orchestratorVersion-input"
            />
            {formData.errors?.orchestratorVersion && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.orchestratorVersion}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>
        );
      case VersionDropdownField.AUTOMATION_SUITE_VERSION:
        return (
          <FormControl
            className={`${baseClass}__Case-Field`}
            disabled={!filteredSupportFormFieldMappings?.automationSuiteVersion}
          >
            <SelectWithSearch
              disabled={!filteredSupportFormFieldMappings?.automationSuiteVersion}
              label={t(
                'support_form_automation_suite_version_label',
                'Automation Suite Version'
              )}
              searchable
              className="Custom-Select--White automationSuiteVersion-input"
              options={props.automationSuiteVersions}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'automationSuiteVersion');
              }}
              value={formData.automationSuiteVersion}
              data-testid="automationSuiteVersion-input"
            />
            {formData.errors?.automationSuiteVersion && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.automationSuiteVersion}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>
        );
      default:
        return (
          <FormControl
            className={`${baseClass}__Case-Field`}
            disabled={!filteredSupportFormFieldMappings?.productComponentVersion}
          >
            <SelectWithSearch
              disabled={!filteredSupportFormFieldMappings?.productComponentVersion}
              label={t(
                'support_form_product_component_version_label',
                'Product Component Version'
              )}
              searchable
              className="Custom-Select--White"
              options={formData.product.productComponentVersions ?? []}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'productComponentVersion');
              }}
              value={formData.productComponentVersion}
              data-testid="productComponentVersion-input"
            />
            {formData.errors?.productComponentVersion && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.productComponentVersion}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>
        );
    }
  };

  const supportIdValidationClass = `SupportCaseBody__Case-Field--${supportIdValidationClassSufix}-Color`;
  return (
    <styles.SupportCaseBody data-testid='describe-issue-page'>
      <div className="NewSupportCaseBody">
        <p className={`${baseClass}__Case-Description`}>
          {t(
            'support_form_describe_issue_description',
            'Help us assist you better by providing as much details as you can about the issue you are facing.'
          )}
        </p>
        <div className={`${baseClass}__Case-Fields`}>
          <FormControl
            className={`${baseClass}__Case-Field`}
          >
            <SelectWithSearch
              label={t('support_form_deployment_type_label', 'Deployment Type')}
              searchable
              className="Custom-Select--White deploymentType-input"
              options={props.deploymentTypes}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'deploymentType');
              }}
              value={formData.deploymentType}
              helpText={t(
                'support_form_deployment_help_text',
                '<b>Automation Cloud:</b> The products/functionality that reside on the UiPath cloud platform <br><b>Automation Suite:</b> The products/functionality that is a part of Automation Suite <br><b>Standalone Service Product:</b> Product installed on-prem/private cloud/client end <br><b>Desktop/Virtual Desktop Client:</b> Typically related to Studio/Robot/Activities <br><b>Not Applicable:</b> Marketplace/Customer Portal-related issues'
              )}
              data-testid="deploymentType-input"
            />
            {formData.errors?.deploymentType && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.deploymentType}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl
            className={`${baseClass}__Case-Field`}
            disabled={!formData.deploymentType.products?.length}
          >
            <SelectWithSearch
              disabled={!formData.deploymentType.products?.length}
              label={t('support_form_product_label', 'Product')}
              searchable
              className="Custom-Select--White product-input"
              options={isRegistered ?
                formData.deploymentType.products ?? [] :
                formData.deploymentType.products?.filter((product: { label: string | string[] }) => !product.label.includes('Embedded Cloud Elements')) ?? []}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'product');
              }}
              value={formData.product}
              helpText={t(
                'support_form_product_help_text',
                'Select the UiPath Product for which you need support.'
              )}
              data-testid="product-input"
            />
            {formData.errors?.product && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.product}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          { showPrimaryVersion() }

          <FormControl
            className={`${baseClass}__Case-Field licensefield`}
            disabled={!filteredSupportFormFieldMappings?.license}
          >
            <SelectWithManualSearch
              disabled={!filteredSupportFormFieldMappings?.license}
              label={isRequiredLicense || formData.product.label.includes('Embedded Cloud Elements') ?
                t('support_form_license_code_or_support_id_label', 'License Code / Support ID') :
                t('support_form_license_code_or_support_id_label_recommended', 'License Code / Support ID (Recommended)')}
              value={formData.supportId}
              isOpen={isOpenLicense}
              onOpen={() => setIsOpenLicense(true)}
              onClose={(e: any) => {
                setIsOpenLicense(false);
                if (e.target.value !== '' && e.target.value !== 0) {
                  handleSelectWithManualOptionChange(e.target.value, 'supportId');
                }
              }}
              onChange={(e: any, newVal: any) => {
                const parsedVal = newVal?.split(' - ')[0] ?? '';
                formData.supportId = parsedVal;
                handleSelectWithManualOptionChange(parsedVal, 'supportId');
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' && e.target.value) {
                  handleSelectWithManualOptionChange(e.target.value, 'supportId');
                }
              }}
              options={licensesOptions}
              placeholder={t(LICENSE_PLACEHOLDER.textKey, LICENSE_PLACEHOLDER.fallbackText)}
              helpText={t(LICENSE_HELP_TEXT.textKey, LICENSE_HELP_TEXT.fallbackText)}
              dataTestId='supportId-input'
              className={`${baseClass}__Case-Field--LicenseField`}
              id='licenseField'
            />
            {loadingSupportIdValidation && (
              <div className={`${baseClass}__Loader-Validation`}>
                <CircularProgress
                  size={16}
                  className='loader' />
                <div className="validation-text">{t('support_form_validating_license_code', 'Validating license code...')}</div>
              </div>
            )}
            {supportIdValidation && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                <span
                  className={supportIdValidationClass}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      supportIdValidation.textKey,
                      supportIdValidation.fallbackText,
                      { sapSupportUrl: SAP_SUPPORT_URL }
                    ),
                  }} />
              </styles.SupportCaseValidationErrorMessage>
            )}
            {formData.errors?.supportId && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.supportId}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel label={t('support_form_subject_label_summary', 'Summary')} />
            <TextField
              placeholder={t('support_form_summary_hint_text', 'Enter a short summary of your issue')}
              variant="outlined"
              value={formData.summary}
              onChange={e => {
                handleInputChange(e, 'summary');
              }}
              error={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{
                maxLength: 150,
                'data-testid': 'summary-input',
              }}
              className={`${baseClass}__Case-Field--Textfield`}
            />
            {formData.errors?.summary && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.summary}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel label={t('support_form_description_label', 'Description')} />
            <TextareaAutosize
              placeholder={t(
                'support_form_description_hint_text',
                'Tell us more about this support request \n - Steps to reproduce\n - The expected and actual outcome\n - What impact is this having'
              )}
              value={formData.description}
              onChange={(e: any) => {
                handleInputChange(e, 'description');
              }}
              className={`${baseClass}__Case-Field--Textarea`}
              aria-label="empty textarea"
              minRows={5}
              data-testid="description-input"
            />
            {formData.errors?.description && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.description}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel label={t('support_form_error_message_label_optional', 'Error Message (Optional)')} />
            <TextField
              placeholder={t('support_form_error_message_hint_text', 'Enter an error message')}
              variant="outlined"
              value={formData.errorMessage}
              onChange={e => {
                handleInputChange(e, 'errorMessage');
              }}
              error={false}
              required={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{
                maxLength: 255,
                'data-testid': 'errorMessage-input',
              }}
              className={`${baseClass}__Case-Field--Textfield`}
            />
          </FormControl>
        </div>
      </div>
    </styles.SupportCaseBody>
  );
};

export default DescribeIssuePage;
