import {
  NONPROD_PRIVILEGED_UNIVERSAL_CP_INVITERS,
  PRIVILEGED_UNIVERSAL_CP_INVITERS,
  UserRole,
} from '@customer-portal/constants';

import { isProduction } from '../lib/env.utils';

export const UIPATH_ROLES_IN_ORDER_ASC: UserRole[] = [
  UserRole.UI_USER,
  UserRole.UI_INVISIBLE_VIEW,
  UserRole.UI_ADMIN,
  UserRole.UI_SUPPORT,
  UserRole.UI_SUPER_CONTENT_EDITOR,
  UserRole.UI_SUPER_OPERATIONS,
  UserRole.UI_SUPER_OPERATIONS_ADMIN,
  UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
  UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
  UserRole.UI_SPECIFIC_ACCOUNT_USER,
  UserRole.UI_SUPER_ADMIN,
];

export const USER_ROLE_TO_DISPLAY_NAME: Partial<Record<UserRole, string>> = {
  [UserRole.CP_ADMIN]: 'CP Admin',
  [UserRole.CP_USER]: 'CP User',
  [UserRole.UI_USER]: 'UiPath User',
  [UserRole.UI_INVISIBLE_VIEW]: 'UiPath Invisible User',
  [UserRole.UI_ADMIN]: 'UiPath Admin',
  [UserRole.UI_SUPER_ADMIN]: 'Super Admin',
  [UserRole.UI_SUPER_CONTENT_EDITOR]: 'UiPath Content Editor',
  [UserRole.UI_SUPER_OPERATIONS]: 'UiPath Operations',
  [UserRole.UI_SUPER_OPERATIONS_ADMIN]: 'UiPath Operations Admin',
  [UserRole.UI_SUPPORT]: 'UiPath Support',
  [UserRole.UI_TECHNICAL_ACCOUNT_MANAGER]: 'Technical Account Manager',
  [UserRole.SFDC_ENTERPRISE_TERRITORY_MANAGEMENT]: 'Enterprise Territory Management',
  [UserRole.UI_SPECIFIC_ACCOUNT_USER]: 'Specific Account User',
  [UserRole.UI_AUTOMATION_PROGRAM_ADVISOR]: 'Automation Program Advisor',
  [UserRole.SFDC_ACCOUNT_OWNER]: 'SFDC Account Owner',
  [UserRole.SFDC_CUSTOMER_SUCCESS_MANAGER]: 'SFDC Customer Success Manager',
  [UserRole.SFDC_TECHNICAL_ACCOUNT_MANAGER]: 'SFDC Technical Account Manager',
  [UserRole.SFDC_AUTOMATION_PROGRAM_ADVISOR]: 'SFDC Automation Program Advisor',
};

export const privilegedUsers = () => {
  if (isProduction()) {
    return PRIVILEGED_UNIVERSAL_CP_INVITERS;
  }
  return [
    ...PRIVILEGED_UNIVERSAL_CP_INVITERS,
    ...NONPROD_PRIVILEGED_UNIVERSAL_CP_INVITERS,
  ];
};

// Constants for scope ids
export const LICENSE = 'license';
export const SUPPORT = 'support';
export const PREMIUMCARE = 'support.premiumCare';
export const COLLAB = 'collab';
export const HAPO = 'hapo.license';
// this is used on profile page
export const SEND_INVITATION_AND_PERMISSION_EDITING =
  'send_invitation_and_permission_editing';
