export const getNumericEnumValues = <T extends Object>(enumObj: T): number[] => (
  Object
    .values(enumObj)
    .filter(v => !isNaN(Number(v)))
    .slice(0, Math.floor(Object.keys(enumObj).length / 2))
);

export const getSortedEnumValues = <T extends Object>(enumObj: T, enumValues: T[]): T[] => {
  const valuesToIndex: Partial<Record<keyof T, number>> = Object
    .values(enumObj)
    .reduce((obj, val, i) => {
      obj[val] = i;
      return obj;
    }, {} as Partial<Record<keyof T, number>>);

  return enumValues.sort((a, b) => {
    const A = valuesToIndex[a as unknown as keyof T];
    const B = valuesToIndex[b as unknown as keyof T];
    if (A === undefined) {
      return -1;
    }
    if (B === undefined) {
      return 1;
    }
    return A > B ? 1 : -1;
  });
};
