import { Header } from '@customer-portal/constants';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import React, {
  useEffect,
  useState,
} from 'react';

// Styles
import * as styles from '../../assets/css/Category/KB-Upload-Modal';
import {
  Locale,
  localeToLanguage,
} from '../../constants/localization.constants';
// Constants
import { KB_CATEGORIES_URL } from '../../constants/network.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import type { DocumentLanguages } from '../../pages/Category';
import Button from '../Button/Button';
import Loader from '../CustomerPortal-Loader';
import Modal from '../Modal';
import Select from '../Select';

type AddAssetModalProps = {
  oldLanguages: DocumentLanguages;
  isEditModalOpen: boolean;
  categoryName: string;
  subcategoryName: string;
  title: string;
  keywords: string;
  description: string;
  sortRanking: number | null;
  featured: boolean;
  notification: boolean;
  handleFormSubmit?: Function;
  handleModalClose: () => void;
};

const EditKBAssetModal = (props: AddAssetModalProps) => {
  const { getAccessToken } = useAuth();
  /* State */
  const [ languages, setLanguages ] = useState<DocumentLanguages>(props.oldLanguages);
  const [ isLoadingFinished, setIsLoadingFinished ] = useState(false);
  const [ sortRanking, setSortRanking ] = useState<number | null>(props.sortRanking);
  const [ subcategory, setSubcategory ] = useState(props.subcategoryName);
  const [ title, setTitle ] = useState(props.title);
  const [ keywords, setKeywords ] = useState(props.keywords);
  const [ description, setDescription ] = useState(props.description);
  const [ featured, setFeatured ] = useState(props.featured);
  const [ notification, setNotification ] = useState(props.notification);
  const [ availableSubcategoriesList, setAvailableSubcategoriesList ] = useState(
    []
  );
  const [ maxSortRankings, setMaxSortRankings ] = useState<{ [subcategoryName: string]: number }>({});
  const [ isUploadProgressStart, setIsUploadProgressStart ] = useState(false);

  // Error messages
  const [ titleError, setTitleError ] = useState('');
  const [ languagesError, setLanguagesError ] = useState('');
  const [ subcategoryError, setSubcategoryError ] = useState('');
  const [ sortRankingError, setSortRankingError ] = useState('');
  const [ descriptionError, setDescriptionError ] = useState('');

  /* Effects */
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const fetchResults = await axios.get(
          `${KB_CATEGORIES_URL}/${props.categoryName}/subcategories`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.AUTH_TYPE]: getAuthType(),
            },
          }
        );

        if (fetchResults.status === 200 && fetchResults.data.data) {
          setAvailableSubcategoriesList(
            fetchResults.data.data.subcategory_list
          );
        }
      } catch (e) {
        console.log(`Error loading subcategories: ${e.toString()}`);
        setSubcategoryError(`Error loading subcategories: ${e.toString()}`);
      }
    };

    const fetchMaxSortRankings = async () => {
      try {
        const fetchResults = await axios.get(
          `${KB_CATEGORIES_URL}/${props.categoryName}/max_sort_rankings`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.AUTH_TYPE]: getAuthType(),
            },
          }
        );

        if (fetchResults.status === 200 && fetchResults.data.data) {
          setMaxSortRankings(fetchResults.data.data);
        }
      } catch (e) {
        console.log(`Error loading sort rankings: ${e.toString()}`);
        setSortRankingError(`Error loading sort rankings: ${e.toString()}`);
      }
    };

    const fetchData = async () => {
      await fetchSubcategories();
      await fetchMaxSortRankings();
      setIsLoadingFinished(true);
    };

    fetchData();
  }, [ props.categoryName ]);

  /* Event */
  // Handle overall form submit
  const handleFormSubmit = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    // If not all fields valid, then return false
    if (!handleSubmitValidate() || !props.handleFormSubmit) {
      return;
    }

    setIsUploadProgressStart(true);

    sendToPropsHandle({
      subcategory,
      name: title,
      keywords,
      description,
      sort_ranking: sortRanking,
      featured,
      notification,
      languages,
    });
  };

  const sendToPropsHandle = async (body: any) => {
    if (!props.handleFormSubmit) {
      return;
    }

    try {
      await props.handleFormSubmit(body);
    } catch (e) {
      console.log('Response returned but error');
      console.log(e.toString());
    } finally {
      handleModalClose();
    }
  };

  const handleSubmitValidate = () => {
    // Required fields
    if (!subcategory) {
      setSubcategoryError(
        'Field is mandatory. If there are no subcategories, please create one on "Manage KB Categories" section under your profile options'
      );
      return false;
    } else if (!title) {
      setTitleError('Field is mandatory');
      return false;
    } else if (!areAnyLanguagesSelected()) {
      setLanguagesError('Please select at least 1 language');
      return false;
    }
    return true;
  };

  const handleSubcategoryOptionClick = (e: any) => {
    if (e.target?.getAttribute('value')) {
      setSubcategory(e.target.getAttribute('value'));
      setSortRanking(null);
    }
  };

  const handleSortRankingOptionClick = (e: any) => {
    if (e.target) {
      const value = e.target.getAttribute('value');
      setSortRanking(value === 'No ranking' ? null : value);
    }
  };

  const handleModalClose = () => {
    // Reset everything
    setFeatured(false);
    setNotification(false);
    setTitle('');
    setKeywords('');
    setDescription('');
    setSortRanking(null);
    setTitleError('');
    setLanguagesError('');
    setSubcategoryError('');
    setSortRankingError('');
    setDescriptionError('');
    setIsUploadProgressStart(false);
    props.handleModalClose();
  };

  // Validation
  const handleTitleBlur = () => {
    if (!title) {
      setTitleError('Field is mandatory');
    } else if (title.length <= 60) {
      setTitleError('');
    }
  };

  // Validate
  const handleTitleChange = (e: any) => {
    if (e && e.target.value.length > 60) {
      setTitle(e.target.value.slice(0, 60));
      setTitleError('Maximum characters allowed is 60 characters');
      return;
    }
    setTitleError('');
    setTitle(e.target.value);
  };

  // Description
  const handleDescriptionBlur = (e: any) => {
    if (description.length <= 255) {
      setDescriptionError('');
    }
  };
  const handleDescriptionChange = (e: any) => {
    if (e && e.target.value.length > 255) {
      setDescription(e.target.value.slice(0, 255));
      setDescriptionError('Maximum characters allowed is 255 characters');
      return;
    }
    setDescriptionError('');
    setDescription(e.target.value);
  };

  const handleToggleLanguage = (language: string) => {
    const newLanguages = {
      ...languages,
      [language]: { active: !languages[language].active },
    };
    if (areAnyLanguagesSelected(newLanguages)) {
      setLanguagesError('');
    }
    setLanguages(newLanguages);
  };

  const handleToggleSelectAllLanguages = () => {
    const set = new Set(Object.values(languages).map(({ active }) => active));
    const newLanguages = Object.keys(languages).reduce((obj: DocumentLanguages, language: string) => {
      const isActive: boolean = set.size === 1 ? !languages[language].active : true;
      obj[language] = { active: isActive };
      return obj;
    }, {});
    if (areAnyLanguagesSelected(newLanguages)) {
      setLanguagesError('');
    }
    setLanguages(newLanguages);
  };

  const areAnyLanguagesSelected = (newLanguages?: DocumentLanguages) => new Set(
    Object.values(newLanguages ?? languages).map(({ active }) => active)
  ).has(true);

  const getSortRankingDropdownOptions = () => {
    let totalNonZeroOptions: number = maxSortRankings[subcategory] ?? 0;
    // Prevent the user from changing a doc that already has a sort ranking to MAX+1 sort ranking
    if (props.sortRanking && totalNonZeroOptions) {
      totalNonZeroOptions--;
    }

    return [
      {
        label: 'No ranking',
        value: 'No ranking',
        selected: false,
      },

      ...Array.from(Array(totalNonZeroOptions).keys()).map(
        (sort_ranking: number) => ({
          label: sort_ranking + 1,
          value: sort_ranking + 1,
          selected: false,
        })
      ),
    ];
  };

  return (
    <Modal
      modalTitle="Edit Resource"
      modalHeading={`Edit Resource "${props.title}"`}
      modalDescription="Modal for editing an existing resource to knowledge base"
      open={props.isEditModalOpen}
      handleClose={handleModalClose}
      innerClasses='KB__Resource-Modal'
    >
      {!isLoadingFinished && <Loader />}
      {isLoadingFinished && (
        <styles.FormWrapper onSubmit={handleFormSubmit}>
          <section className="EditForm__ContentSection UploadForm__ContentSection">
            <styles.FormControlWrapper className="">
              <div>
                <FormControlLabel
                  className="KnowledgeCategories__Checkbox"
                  control={
                    <Checkbox
                      checked={!Object.values(languages).map(({ active }) => active)
                        .includes(false)}
                      onChange={handleToggleSelectAllLanguages}
                      name="Select All"
                    />
                  }
                  label="Select All"
                />
                {Object.entries(languages).map(([ language, { active } ]: [string, { active: boolean }], index: number) => (
                  <FormControlLabel
                    key={index}
                    className="KnowledgeCategories__Checkbox"
                    control={
                      <Checkbox
                        checked={active}
                        onChange={(_e) => handleToggleLanguage(language)}
                        name={Locale[language as keyof typeof Locale]}
                      />
                    }
                    label={`${localeToLanguage.get(Locale[language as keyof typeof Locale])} (${language})`}
                  />
                ))}
              </div>
              <div className="ErrorMessage">
                {languagesError}
              </div>
            </styles.FormControlWrapper>

            {/* Subcategory */}
            <styles.FormControlWrapper className="UploadForm__FormControl">
              <span className="UploadForm__SubCategoryLabel">
                Sub-Category (Required)
              </span>
              <Select
                className={
                  isUploadProgressStart
                    ? 'UploadForm__Select--Disabled'
                    : undefined
                }
                onChange={handleSubcategoryOptionClick}
                selected={{
                  label: subcategory,
                  value: subcategory,
                }}
                id="subcategory_select"
                options={
                  (availableSubcategoriesList?.length
                    ? availableSubcategoriesList
                    : []
                  )
                    .map((availableSubcategory: any) => ({
                      label: availableSubcategory.languages[Locale.en].name,
                      value: availableSubcategory.languages[Locale.en].name,
                      selected: false,
                    }))
                }
                placeholderText="Choose subcategory"
                helperText={subcategoryError ? subcategoryError : undefined}
              />
            </styles.FormControlWrapper>

            {/* Sort Ranking */}
            <styles.FormControlWrapper className="UploadForm__FormControl">
              <span className="UploadForm__SortRankingLabel">
                Sub-Category Sort Ranking (Optional)
              </span>
              <Select
                className="UploadForm__SortRankingDropdown"
                onChange={handleSortRankingOptionClick}
                selected={{
                  label: sortRanking ? sortRanking : 'No ranking',
                  value: sortRanking ? sortRanking : 'No ranking',
                }}
                id="sort_ranking_select"
                options={getSortRankingDropdownOptions()}
                placeholderText="Choose sort ranking"
                helperText={sortRankingError ? sortRankingError : undefined}
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl">
              <styles.TextInput
                className="UploadForm__Input"
                value={title}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                placeholder="Add a title..."
                variant={'outlined' as any}
                helperText={titleError ? titleError : undefined}
                disabled={isUploadProgressStart}
                data-testid="upload-title-input"
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl">
              <styles.TextInput
                className="UploadForm__Input"
                value={keywords}
                onChange={e => setKeywords(e.target.value)}
                placeholder="Add keywords, separated by space"
                variant={'outlined' as any}
                disabled={isUploadProgressStart}
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__DescriptionArea">
              <styles.TextInput
                className="UploadForm__Textarea"
                value={description}
                onChange={handleDescriptionChange}
                onBlur={handleDescriptionBlur}
                rows={2}
                multiline
                placeholder="Add a short description"
                variant={'outlined' as any}
                disabled={isUploadProgressStart}
                helperText={descriptionError}
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__TwoColumnRow">
              <Switch
                checked={notification}
                onChange={() => setNotification(!notification)}
                value={notification}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={isUploadProgressStart}
              />
              <styles.SwitchText>
                <h6>Notification</h6>
                <p>
                  By activating this, you will make the document display in the “Notification” icon.
                  <br />
                  This should be used for urgent updates.
                </p>
              </styles.SwitchText>
            </styles.FormControlWrapper>
            <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__TwoColumnRow">
              <Switch
                checked={featured}
                onChange={() => setFeatured(!featured)}
                value={featured}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={isUploadProgressStart}
              />
              <styles.SwitchText>
                <h6>Featured</h6>
                <p>
                  By activating this you will make the document featured on the
                  knowledge base home page.
                </p>
              </styles.SwitchText>
            </styles.FormControlWrapper>

            <Button
              className="UploadForm__SubmitButton"
              onClick={() => { }}
              isValid={isUploadProgressStart ? false : undefined}
              disabled={isUploadProgressStart}
              text={
                isUploadProgressStart
                  ? `Uploading...`
                  : `Edit Resource on ${props.categoryName}`
              }
            />
          </section>
        </styles.FormWrapper>
      )}
    </Modal>
  );
};

export default EditKBAssetModal;
