import type { KBEmbeddedVideoType } from '@customer-portal/constants';
import {
  Header,
  KBDocumentType,
} from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../../assets/css/Knowledge/Document';
// Images
import MoreVT from '../../assets/img/svg/file_icons/More_VT.svg';
import type { VideoThumbnailSize } from '../../constants/knowledge.constants';
import type { Locale } from '../../constants/localization.constants';
// Constants
import { KB_ASSETS_URL } from '../../constants/network.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import downloadDocument from '../../lib/customerPortalDownload.utils';
// Utils
import { calcFileToReadable } from '../../lib/file.utils';
import { getStringEnv } from '../../lib/index.util';
import { getVideoURL } from '../../lib/knowledgebaseDocument.utils';
import type { DocumentLanguages } from '../../pages/Category';
import { StoreContext } from '../../store/index';
import Button from '../Button/Button';
import EditModal from '../category/KB-Edit-Modal';
// Components
import Modal from '../Modal';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

interface UnLocalizedCategoryInfo {
  id: string;
  // Always provide a default to English
  languages: Partial<Record<Locale, { name: string }>> & Record<Locale.en, { name: string }>;
}

type KBDocumentProps = {
  category: UnLocalizedCategoryInfo;
  subcategory: UnLocalizedCategoryInfo;
  documentTitleText: string;
  isDisplayDescription: boolean | undefined;
  documentDescriptionText: string | undefined;
  keywords: string;
  featured: boolean;
  sortRanking: number | null;
  notification: boolean;
  mimeType: string | undefined;
  linkHref: string | undefined;
  fileSize: string | undefined;
  fileId: string;
  specs?: { [duration: string]: number };
  dataTestId?: string;
  handleDeleteFormSubmit?: (
    fileId: string,
    categoryName: string,
    subcategoryName: string
  ) => Promise<void>;
  languages: DocumentLanguages;
  type: KBDocumentType;
  embeddedVideoRaw: string;
  embeddedVideoType: KBEmbeddedVideoType;
  embeddedVideoId: string;
  embeddedVideoThumbnailSize: VideoThumbnailSize;
};

/**
 *
 * Helper component reused in more than one screen
 */

const CustomerPortalKBDocument = ({
  category,
  subcategory,
  documentTitleText,
  isDisplayDescription,
  documentDescriptionText,
  keywords,
  featured,
  sortRanking,
  notification,
  mimeType,
  linkHref,
  fileSize,
  fileId,
  specs,
  dataTestId = '',
  handleDeleteFormSubmit,
  languages,
  type,
  embeddedVideoRaw,
  embeddedVideoType,
  embeddedVideoId,
  embeddedVideoThumbnailSize,
}: KBDocumentProps) => {
  /* State */
  const [ adminOptionsAnchorElem, setAdminOptionsAnchorElem ] = useState(null);
  const [ isHovering, setIsHovering ] = useState(false);
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);
  const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
  const [ shouldOpenVideo, setShouldOpenVideo ] = useState(false);
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const { i18n } = useTranslation('common');

  const language = i18n.language as Locale;

  const localizedSubcategoryName = subcategory.languages[language]?.name ?? subcategory.languages.en.name;

  const englishCategoryName = category.languages.en.name;
  const englishSubcategoryName = subcategory.languages.en.name;

  // Helper function to get file properties
  const readableFields = calcFileToReadable(
    fileSize,
    mimeType,
    linkHref,
    specs ? specs.duration : 0
  );

  // Event handlers
  const handleMouseOver = (e: React.MouseEvent<HTMLInputElement>) => {
    setIsHovering(true);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
    setIsHovering(false);
  };

  const handleClick = async (e: React.MouseEvent<HTMLInputElement>) => {
    // If the video popup is already open, don't open it again
    if (type === KBDocumentType.EMBEDDED_VIDEO && shouldOpenVideo) {
      return;
    }

    if (type === KBDocumentType.FILE) {
      try {
        await downloadDocument(`${KB_ASSETS_URL}/${fileId}`, undefined, {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        });
      } catch (e) {
        const errorMessage = e.response?.data?.data
          ? `Error downloading document: ${e.response.data.data}`
          : `Error downloading document: ${e.toString()}`;
        console.log(errorMessage);

        dispatchContext({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: errorMessage,
        });
      }
    }

    try {
      if (type === KBDocumentType.LINK && !linkHref) {
        throw Error('No link provided');
      }

      if (type === KBDocumentType.EMBEDDED_VIDEO && !embeddedVideoRaw) {
        throw Error('No video provided');
      }

      await axios.post(`${KB_ASSETS_URL}/${fileId}`, undefined, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: getAuthType(),
        },
      });

      if (type === KBDocumentType.LINK) {
        const tempAnchor = document.createElement('a');
        tempAnchor.href = linkHref as string;
        tempAnchor.target = '_blank';
        document.body.appendChild(tempAnchor);
        tempAnchor.click();
        document.body.removeChild(tempAnchor);
      }

      if (type === KBDocumentType.EMBEDDED_VIDEO) {
        !shouldOpenVideo && setShouldOpenVideo(true);
      }
    } catch (e) {
      const errorMessage =
        e.response?.data?.data
          ? `Error opening document: ${e.response.data.data}`
          : `Error opening document: ${e.toString()}`;
      console.log(errorMessage);

      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const handleCloseVideoPopup = () => {
    shouldOpenVideo && setShouldOpenVideo(false);
  };

  // For popover actions
  const handleAdminOptionsClick = (e: any) => {
    if (e.currentTarget !== adminOptionsAnchorElem) {
      setAdminOptionsAnchorElem(e.currentTarget);
    }
  };

  const handleDeleteOptionClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleEditOptionClick = () => {
    // Lift modal up to parent
    setIsEditModalOpen(true);
  };

  const handleDeleteFormSubmitLocal = (e: any) => {
    e.preventDefault();

    if (handleDeleteFormSubmit) {
      handleDeleteFormSubmit(fileId, englishCategoryName, englishSubcategoryName);
      setIsDeleteModalOpen(false);
      setAdminOptionsAnchorElem(null);
    }
  };

  const handleEditModalSubmit = async (editedDoc: { [key: string]: any }) => new Promise<void>(async (resolve, reject) => {
    try {
      const patchResult = await axios.patch(
        `${KB_ASSETS_URL}/${fileId}`,
        { doc: editedDoc },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        }
      );

      if (patchResult.data.statusCode === 201) {
        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: `Resource ${editedDoc.name} has been updated.`,
        });

        window.location.reload();
      }
      resolve();
    } catch (e) {
      const errorMessage =
        e.response?.data
          ? `Error updating file: ${e.response.data.data ||
          e.response.data.message}`
          : `Error updating file: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      reject(e);
    }
  });

  return (
    <styles.DocumentAdminWrapper
      className="CustomerPortalKBDocument"
      data-testid={dataTestId}>
      <styles.DocInfoWrapper
        data-documenttype={readableFields.internalFileType} // For dynamic background color
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <styles.DocumentInfo>
          <styles.SubCategoryText>{localizedSubcategoryName}</styles.SubCategoryText>
          <styles.KBTitle className="CustomerPortalKBDocument__KnowledgeBaseTitle">
            {documentTitleText}
          </styles.KBTitle>
          {isDisplayDescription && (
            <p className="CustomerPortalKBDocument__KnowledgeBaseDescription">
              {documentDescriptionText}
            </p>
          )}
        </styles.DocumentInfo>

        {type === KBDocumentType.EMBEDDED_VIDEO ? (
          <VideoPlayer
            open={shouldOpenVideo}
            onClose={handleCloseVideoPopup}
            src={getVideoURL(embeddedVideoRaw, embeddedVideoType)}
            embeddedVideoRaw={embeddedVideoRaw}
            embeddedVideoType={embeddedVideoType}
            embeddedVideoId={embeddedVideoId}
            embeddedVideoThumbnailSize={embeddedVideoThumbnailSize}
          />
        ) : (
          <styles.DownloadIconWrapper>
            <styles.DownloadIconContainer className="CustomerPortalDownloadIcon__ImageContainer">
              <img
                src={
                  isHovering
                    ? readableFields.downloadIconHover
                    : readableFields.downloadIcon
                }
                className="CustomerPortalDownloadIcon__Image"
                alt="Download Icon"
              />
            </styles.DownloadIconContainer>
            <styles.DocInfoContainer>
              <styles.DocExtension className="Small">
                {readableFields.readableFileExtension}
              </styles.DocExtension>
              <styles.DocFileSize>
                {readableFields.readableFileSize}
              </styles.DocFileSize>
            </styles.DocInfoContainer>
          </styles.DownloadIconWrapper>
        )}

      </styles.DocInfoWrapper>
      {typeof handleDeleteFormSubmit === 'function' && (
        <>
          <styles.AdminOptionsMenu
            src={MoreVT}
            alt="Options"
            onClick={handleAdminOptionsClick}
            data-testid="AdminOptionsMenu"
          />
          <styles.AdminPopover
            open={Boolean(adminOptionsAnchorElem)}
            elevation={0}
            anchorEl={adminOptionsAnchorElem}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => {
              setAdminOptionsAnchorElem(null);
            }}
            disableRestoreFocus
            data-testid="AdminPopover"
          >
            <styles.AdminPopoverOption
              onClick={handleEditOptionClick}
              data-testid="EditLink">
              Edit
            </styles.AdminPopoverOption>
            <styles.AdminPopoverOption
              onClick={handleDeleteOptionClick}
              data-testid="DeleteLink">
              Delete
            </styles.AdminPopoverOption>
            <styles.AdminPopoverOption
              onClick={(e: any) => {
                const url =
                  `${getStringEnv(process.env.PUBLIC_URL)}/knowledge-base/category/${englishCategoryName}/${linkHref ? 'link' : 'media'}/${fileId}}`;
                navigator.clipboard.writeText(url);
                setAdminOptionsAnchorElem(null);
              }}
              data-testid="CopyLink">
              Copy Link
            </styles.AdminPopoverOption>
          </styles.AdminPopover>
          {isDeleteModalOpen && (
            <Modal
              modalTitle="Delete Document"
              modalDescription="Delete selected document"
              open={isDeleteModalOpen}
              handleClose={() => {
                setIsDeleteModalOpen(false);
                setAdminOptionsAnchorElem(null);
              }}
              modalHeading="Are you sure you want to delete this document?"
            >
              <styles.DeleteFormWrapper onSubmit={handleDeleteFormSubmitLocal}>
                <Button
                  className="KnowledgeCategories__SubmitButton"
                  onClick={() => { }}
                  text="Submit"
                />
              </styles.DeleteFormWrapper>
            </Modal>
          )}
          {isEditModalOpen && (
            <EditModal
              oldLanguages={languages}
              isEditModalOpen={isEditModalOpen}
              categoryName={englishCategoryName}
              subcategoryName={englishSubcategoryName}
              title={documentTitleText}
              keywords={keywords}
              description={documentDescriptionText || ''}
              sortRanking={sortRanking}
              featured={featured}
              notification={notification}
              handleFormSubmit={handleEditModalSubmit}
              handleModalClose={() => {
                setIsEditModalOpen(false);
                setAdminOptionsAnchorElem(null);
              }}
            />
          )}
        </>
      )}
    </styles.DocumentAdminWrapper>
  );
};

export default CustomerPortalKBDocument;
